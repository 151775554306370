import React, { useState } from "react";
import { Fade, Zoom } from "react-reveal";

import firstBlack from "../../../images/servicesassets/costing/firstBlack.png";
import firstIn from "../../../images/servicesassets/costing/firstIn.png";
import firstJa from "../../../images/servicesassets/costing/firstJa.png";
import firstEn from "../../../images/servicesassets/costing/firstEn.png";

import secondBlack from "../../../images/servicesassets/costing/secondBlack.png";
import secondIn from "../../../images/servicesassets/costing/secondIn.png";
import secondJa from "../../../images/servicesassets/costing/secondJa.png";
import secondEn from "../../../images/servicesassets/costing/secondEn.png";

import thirdBlack from "../../../images/servicesassets/costing/thirdBlack.png";
import thirdIn from "../../../images/servicesassets/costing/thirdIn.png";
import thirdJa from "../../../images/servicesassets/costing/thirdJa.png";
import thirdEn from "../../../images/servicesassets/costing/thirdEn.png";

import fourthBlack from "../../../images/servicesassets/costing/fourthBlack.png";
import fourthIn from "../../../images/servicesassets/costing/fourthIn.png";
import fourthJa from "../../../images/servicesassets/costing/fourthJa.png";
import fourthEn from "../../../images/servicesassets/costing/fourthEn.png";
import { useLanguage } from "../../../languageContext";

const WhyNeedsCost = () => {
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const currentLanguage = useLanguage();
  function hoverFirst() {
    setFirst(true);
  }
  function hoverSecond() {
    setSecond(true);
  }
  function hoverThird() {
    setThird(true);
  }
  function hoverFourth() {
    setFourth(true);
  }
  return (
    <div className="section costing-why-required-wrapper section-br p-top-3em">
      <h2 className="heading w-90-wrapper">
        Why do companies need Should Cost Optimization?
      </h2>
      <p className="para">
        Assessing true cost of a product requires detailed due diligence of
        material cost, manufacturing process, and logistics or delivery cost.
        This information along with the willingness of the market to pay on top
        as profit defines the true cost of the product.​
      </p>

      <Fade duration={1000}>
        <div className="five-section para w-90-wrapper ">
          <div
            className="Second-section onethird-section"
            onMouseEnter={hoverFirst}
            onMouseLeave={() => {
              setFirst(false);
            }}
          >
            <div className="section-wrapper">
              <Zoom delay={100}>
                <div className="image">
                  {first ? (
                    currentLanguage === "en" ? (
                      <img src={firstEn} alt="cost engineering" />
                    ) : currentLanguage === "in" ? (
                      <img src={firstIn} alt="cost engineering" />
                    ) : currentLanguage === "ja" ? (
                      <img src={firstJa} alt="cost engineering" />
                    ) : (
                      <img src={firstBlack} alt="cost engineering" />
                    )
                  ) : (
                    <img src={firstBlack} alt="cost engineering" />
                  )}
                </div>
              </Zoom>
              <h3 className="content para">
                Serve as a tool for supplier negotiation​
              </h3>
            </div>
          </div>
          <div
            className="third-section onethird-section"
            onMouseEnter={hoverSecond}
            onMouseLeave={() => {
              setSecond(false);
            }}
          >
            <div className="section-wrapper">
              <Zoom delay={100}>
                <div className="image">
                  {second ? (
                    currentLanguage === "en" ? (
                      <img src={secondEn} alt="cost engineering" />
                    ) : currentLanguage === "in" ? (
                      <img src={secondIn} alt="cost engineering" />
                    ) : currentLanguage === "ja" ? (
                      <img src={secondJa} alt="cost engineering" />
                    ) : (
                      <img src={secondBlack} alt="cost engineering" />
                    )
                  ) : (
                    <img src={secondBlack} alt="cost engineering" />
                  )}
                </div>
              </Zoom>
              <p className="content para">Measure cost savings potential </p>
            </div>
          </div>
          <div
            className="fourth-section onethird-section"
            onMouseEnter={hoverThird}
            onMouseLeave={() => {
              setThird(false);
            }}
          >
            <div className="section-wrapper">
              <Zoom delay={100}>
                <div className="image">
                  {third ? (
                    currentLanguage === "en" ? (
                      <img src={thirdEn} alt="cost engineering" />
                    ) : currentLanguage === "in" ? (
                      <img src={thirdIn} alt="cost engineering" />
                    ) : currentLanguage === "ja" ? (
                      <img src={thirdJa} alt="cost engineering" />
                    ) : (
                      <img src={thirdBlack} alt="cost engineering" />
                    )
                  ) : (
                    <img src={thirdBlack} alt="cost engineering" />
                  )}
                </div>
              </Zoom>
              <p className="content para">Help in Make vs Buy decisions​</p>
            </div>
          </div>
          <div
            className="fifth-section onethird-section"
            onMouseEnter={hoverFourth}
            onMouseLeave={() => {
              setFourth(false);
            }}
          >
            <div className="section-wrapper">
              <Zoom delay={100}>
                <div className="image">
                  {fourth ? (
                    currentLanguage === "en" ? (
                      <img src={fourthEn} alt="cost engineering" />
                    ) : currentLanguage === "in" ? (
                      <img src={fourthIn} alt="cost engineering" />
                    ) : currentLanguage === "ja" ? (
                      <img src={fourthJa} alt="cost engineering" />
                    ) : (
                      <img src={fourthBlack} alt="cost engineering" />
                    )
                  ) : (
                    <img src={fourthBlack} alt="cost engineering" />
                  )}
                </div>
              </Zoom>
              <p className="content para">Improve in-house productivity​</p>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};
export default WhyNeedsCost;
