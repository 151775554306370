import { Link } from "gatsby";
import React from "react";
import { Fade } from "react-reveal";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import { useLanguage } from "../../languageContext";
import bgImage from "../../images/staffingAssets/timeline_bg.png";

const StaffingNewTimelineComponent = ({
  heading,
  paragraph,
  items,
  bannerService,
  removeLastSection,
  style = "noParagraph",
  ctaFirst = false,
  twoMode = false,
}) => {
  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  const currentLanguage = useLanguage();

  return (
    <div className="staffing-horizonatal-timeline-component section-br m-top-3em p-bottom-3em">
      {ctaFirst && (
        <ImageBanner
          heading={`Interested to learn more About ${bannerService}?`}
          btnText="Get in Touch"
          link="/contactus/"
        />
      )}
      <div className="w-90-wrapper">
        <h2 className="heading">{heading}</h2>
        <p className="para">{paragraph}</p>
        <div
          className={`horizontal-timeline-wrapper ${
            twoMode
              ? "row-2-items"
              : items.length <= 6
              ? " row-3-items"
              : " row-4-items"
          }`}
        >
          <div
            className="background-image-container desktop-wrapper"
            style={{ backgroundImage: bgImage }}
          ></div>
          {items.length > 0
            ? items.map(
                ({ heading, paragraph, isList, listItem, tagline }, index) => {
                  return (
                    <div
                      className={`staffing-item-inner-wrapper  ${
                        (index + 1) % 3 === 0 ? "padding-right-0" : ""
                      } show`}
                    >
                      <Fade
                        distance="50%"
                        right
                        duration={500}
                        delay={(index + 1) * 300}
                      >
                        {/* <div className="timeline-number-wrapper">
                          <h3
                            className={`timeline-number bg-${
                              currentLanguage ? currentLanguage : "en"
                            }`}
                          >
                            {pad(index + 1, 2)}
                          </h3>
                        </div> */}
                        <div
                          className={`staffing-item ${
                            style === "noParagraph"
                              ? " center-staffing-item"
                              : " top-staffing-item"
                          }`}
                        >
                          <div className="staffing-item-content">
                            <h3 className={`title regular no-margin`}>
                              {pad(index + 1, 2)}
                            </h3>
                            <h3
                              className={`no-margin staffing-item-heading ${
                                style === "noParagraph"
                                  ? "para"
                                  : "title bold cardheading-bottom-margin"
                              }`}
                            >
                              {heading}
                            </h3>
                            <div className="staffing-item-heading para">
                              <p>
                                {paragraph ? paragraph : tagline ? tagline : ""}
                              </p>
                              {isList ? (
                                <ul>
                                  {listItem
                                    ? listItem.map((listItem) => (
                                        <li>{listItem}</li>
                                      ))
                                    : null}
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  );
                }
              )
            : null}
        </div>
      </div>
      {!ctaFirst && !removeLastSection && (
        <ImageBanner
          heading={`Interested to learn more About ${bannerService}?`}
          btnText="Get in Touch"
          link="/contactus/"
        />
      )}
    </div>
  );
};
export default StaffingNewTimelineComponent;
